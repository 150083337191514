import {
  FETCH_USERS_REQUEST,
  FETCH_USERS_FAILURE,
  FETCH_USERS_SUCCESS,
  UPDATE_USER_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  USER_GROUPS_FAILURE,
  USER_GROUPS_REQUEST,
  USER_GROUPS_SUCCESS,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  SET_SELECTED_ACCOUNT
} from './user-search-actions';
import { normalizeText } from  '../../common/utils/stringAndDateHelpers'
import { appStore } from '../../index';

function reduxNormalizer(collection) {
  let byId = {};
  collection.forEach(c => byId[c.id] = c);
  return {
    byId: byId,
    allIds: collection.map(c => c.id)
  };
}

const initialState = {
  isFetching: false,
  isUpdating: false,
  isPosting: false,
  isFetchingGroups: false,
  byAcctId: {},
  byId: {},
  allIds: [],
  groups: {},
  selectedAccountId: null
};

export default function userSearchReducer(state = {...initialState}, action) {
  switch(action.type) {
    case FETCH_USERS_REQUEST: {
      return {...state, isFetching: true};
    }
    case FETCH_USERS_SUCCESS: {
      let norm = reduxNormalizer(action.users);
      let byAcctId = {}
      for (let userId in norm.byId) {
        byAcctId[norm.byId[userId].account_id] = norm.byId[userId]
      }
      return {
        ...state,
        isFetching: false,
        byId: norm.byId,
        allIds: norm.allIds,
        byAcctId
      };
    }
    case FETCH_USERS_FAILURE: {
      return {...state, isFetching: false };
    }
    case UPDATE_USER_REQUEST: {
      return {...state, isUpdating: true};
    }
    case UPDATE_USER_SUCCESS: {
      let byId = {...state.byId, [action.user.id]: action.user}
      return {...state, isUpdating: false, byId};
    }
    case UPDATE_USER_FAILURE: {
      return {...state, isUpdating: false};
    }
    case USER_GROUPS_REQUEST:
      return {
        ...state,
        isFetchingGroups: true,
      };
    case USER_GROUPS_SUCCESS: {
      const store = appStore.getState()
      const user = store.auth.user
      let groups = action.groups
      if (!user?.flags?.['grant-access']){
        delete groups['GRANT_ACCESS']
        delete groups['CUSTOMER_EDIT']
      }
      return {
        ...state,
        isFetchingGroups: false,
        groups: groups
      };
    }
    case USER_GROUPS_FAILURE:
      return {
        ...state,
        isFetchingGroups: false,
        error: action.error
      };
    case DELETE_USER_REQUEST: {
      return { ...state, isPosting: action.isPosting };
    }
    case DELETE_USER_SUCCESS: {
      const { userId } = action;
      const newById = Object.assign({}, state.byId);
      delete newById[userId];
      const newAllIds = [...state.allIds];
      return {
        ...state,
        isPosting: false,
        allIds: newAllIds.filter(item => item !== userId),
        byId: newById
      }
    }
    case DELETE_USER_FAILURE: {
      return {
        ...state,
        isPosting: false,
        errorMessage: action.error
      };
    }

    case SET_SELECTED_ACCOUNT:{
      return {
        ...state,
        selectedAccountId: action.acctId
      }
    }
    default: {
      return state;
    }
  }
}


export function getAllUsersById(store) {
  return store.entities.users.byId;
}
export function getUserByEmail(store, targetEmail) {
  const users = Object.values(store.entities.users.byId);
  return users.filter(u => u.email === targetEmail);
}

export function getUsersByEmailFuzzy(store, email) {
  const users = Object.values(store.entities.users.byId);
  email = normalizeText(email);
  return users.filter(user => {
    return user.email.includes(email);
  }).map(user => user.id);
}

export function getUsersByNameFuzzy(store, name) {
  const users = Object.values(store.entities.users.byId);
  let normName = normalizeText(name);
  let filtered = users.filter(user => {
    return normalizeText(`${user.first_name} ${user.last_name}`).includes(normName) ||
            normalizeText(`${user.last_name} ${user.first_name}`).includes(normName) ;
  });
  return  filtered.map(user => user.id);


}
export function getAccountByEmail(store, targetEmail) {
  const users = Object.values(store.entities.users.byId);
  const usersByEmail = users.filter(u => u.email === targetEmail);
  return usersByEmail[0].account_id;
}
export function getAccountsByEmail(store, targetEmail) {
  const users = Object.values(store.entities.users.byId);
  const usersByEmail = users.filter(u => u.email === targetEmail);
  const accounts = usersByEmail.map(u => u.account_id);
  return accounts;
}
export function getAccountsByEmailFuzzy(store, targetFuzzyEmail) {
  const users = Object.values(store.entities.users.byId);
  targetFuzzyEmail = normalizeText(targetFuzzyEmail);
  return users.filter(user => user.email.includes(targetFuzzyEmail)).map(user => user.account_id);
}
export function getEmailsByAccount(store, accountNumber) {
  const users = Object.values(store.entities.users.byId);
  const emails = users.filter(u => u.account_id === accountNumber).map(u =>  u.email);
  return emails;
}
export function getUsersByAccount(store, accountNumber) {
  const users = Object.values(store.entities.users.byId);
  const accountUsers = users.filter(u => u.account_id === accountNumber);
  return accountUsers;
}

import {
  GET_ACCOUNT_FIELDS_REQUEST ,
  GET_ACCOUNT_FIELDS_FAILURE,
  GET_ACCOUNT_FIELDS_SUCCESS,

  TRANSFER_FIELDS_REQUEST,
  TRANSFER_FIELDS_SUCCESS,
  TRANSFER_FIELDS_FAILURE,

} from './fields-actions'

import { createSelector } from 'reselect'


const initialState = {
  pending: false,
  pendingAccounts: new Set(),
  hasError: false,
  error: null,
  fieldEntities: null,
  isTransferring: false,
}

export const fieldsReducer = (state = { ...initialState }, action) => {
  switch(action.type) {

    ////////////////////////
    // Get Handlers
    ////////////////////////
    case GET_ACCOUNT_FIELDS_REQUEST: {
      return { ...state, pending: true, pendingAccounts: state.pendingAccounts.add(action.accountId) }
    }
    case GET_ACCOUNT_FIELDS_SUCCESS: {
      state.pendingAccounts.delete(action.accountId)
      const fieldEntities = {}

      action.payload.forEach(entity => {
        fieldEntities[entity.id] = entity
      })
      return {
        ...state,
        pending: !!state.pendingAccounts.size,
        hasError: false,
        fieldEntities: {
          ...state.fieldEntities,
          [action.accountId]: fieldEntities
        }
      }
    }
    case  GET_ACCOUNT_FIELDS_FAILURE: {
      return {
        ...state,
        pending: false,
        hasError: true,
        error: action.error
      }
    }

    ////////////////////////
    // Transfer Field Handlers
    ////////////////////////

    case TRANSFER_FIELDS_REQUEST: {
      return { ...state, isTransferring: true }
    }

    case TRANSFER_FIELDS_SUCCESS: {
      return {
        ...state,
        isTransferring: false,
        error: null
      }
    }

    case TRANSFER_FIELDS_FAILURE: {
      return {
        ...state,
        isTransferring: false,
        error: action.error
      }
    }

    default: {
      return state
    }
  }
}


//////////////////
// SELECTORS
//////////////////
const getSelectedAccountId = state => state.entities.users.selectedAccountId
const getFieldsByAccount = state => state.entities.fields.fieldEntities
const getAccountFields = (fields, acct) => {
  let allfields =  {...fields}
  return allfields[acct]
}

export const getOwnFields = createSelector(
  getFieldsByAccount,
  getAccountFields
)
export const getFieldsForSelectedAccount = createSelector(
  getFieldsByAccount,
  getSelectedAccountId,
  getAccountFields
)

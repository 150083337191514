import {
  getBaseApiHelper,
  patchBaseApiHelper,
  deleteBaseApiHelper
} from '../../common/utils/apiHelpers';

export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST';
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';

export const FETCH_ACCOUNTS_REQUEST = 'FETCH_ACCOUNTS_REQUEST';
export const FETCH_ACCOUNTS_FAILURE = 'FETCH_ACCOUNTS_FAILURE';
export const FETCH_ACCOUNTS_SUCCESS = 'FETCH_ACCOUNTS_SUCCESS';

export const GET_ACCOUNT_REQUEST = 'GET_ACCOUNT_REQUEST'
export const GET_ACCOUNT_SUCCESS = 'GET_ACCOUNT_SUCCESS'
export const GET_ACCOUNT_FAILURE = 'GET_ACCOUNT_FAILURE'

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';

export const USER_GROUPS_REQUEST = 'USER_GROUPS_REQUEST';
export const USER_GROUPS_SUCCESS = 'USER_GROUPS_SUCCESS';
export const USER_GROUPS_FAILURE = 'USER_GROUPS_FAILURE';

export const SET_SELECTED_ACCOUNT = 'SET_SELECTED_ACCOUNT'

// Action Creators
function usersRequest() {
  return {
    type: FETCH_USERS_REQUEST,
    isFetching: true
  };
}

function usersSuccess(users) {
  return {
    type: FETCH_USERS_SUCCESS,
    users: users
  };
}

function usersFailure(users) {
  return {
    type: FETCH_USERS_FAILURE,
    users: users
  };
}

function accountsRequest() {
  return {
    type: FETCH_ACCOUNTS_REQUEST,
    isFetching: true
  };
}

function accountsSuccess(accounts) {
  return {
    type: FETCH_ACCOUNTS_SUCCESS,
    accounts: accounts
  };
}

function accountsFailure(error) {
  return {
    type: FETCH_ACCOUNTS_FAILURE,
    error: error
  };
}

function accountRequest() {
  return {
    type: GET_ACCOUNT_REQUEST,
  }
}

function accountSuccess(account) {
  return {
    type: GET_ACCOUNT_SUCCESS,
    account: account
  }
}

function accountFailure(error) {
  return {
    type: GET_ACCOUNT_FAILURE,
    error: error
  }
}

function userUpdateRequest(){
  return {
    type: UPDATE_USER_REQUEST,
    isFetching: true
  };
}
function userUpdateSuccess(user){
  return {
    type: UPDATE_USER_SUCCESS,
    user
  };
}
function userUpdateFailure(){
  return {
    type: UPDATE_USER_FAILURE,
  };
}

export function userGroupsRequest() {
  return {
    type: USER_GROUPS_REQUEST,
    isFetchingGroups: true,

  };
}

export function userGroupsSuccess(groups) {
  return {
    type: USER_GROUPS_SUCCESS,
    isFetchingGroups: false,
    groups: groups
  };
}

export function userGroupsFailure() {
  return {
    type: USER_GROUPS_FAILURE,
    isFetchingGroups: false
  };
}

export function deleteUserRequest() {
  return {
    type: DELETE_USER_REQUEST,
    isPosting: true,
  };
}

export function deleteUserSuccess(userId) {
  return {
    type: DELETE_USER_SUCCESS,
    userId
  };
}

export function deleteUserFailure(responseError) {
  return {
    type: DELETE_USER_FAILURE,
    error: responseError
  };
}

export function setSelectedAccount(acctId) {
  return {
    type: SET_SELECTED_ACCOUNT,
    acctId: acctId,

  };
}

export function fetchAllUsers() {
  return dispatch => {
    dispatch(usersRequest());
    return getBaseApiHelper('/users')
      .then(response => {
        dispatch(usersSuccess(response.data));
        return response.data;
      })
      .catch(error => {
        dispatch(usersFailure(error));
        return error.response;
      });
  };
}

export function updateUser(userId, values){
  return dispatch => {
    dispatch(userUpdateRequest());
    return patchBaseApiHelper(`/users/${userId}`, values)
      .then(response => {
        dispatch(userUpdateSuccess(response.data));
        return response.data;
      })
      .catch(error => {
        dispatch(userUpdateFailure(error));
        return Promise.reject(error.response);
      });
  };
}
export function fetchUserGroups(){
  return dispatch => {
    dispatch(userGroupsRequest());
    getBaseApiHelper('/users/groups')
      .then(response => {
        dispatch(userGroupsSuccess(response.data.groups));
      }).catch(error => {
        dispatch(userGroupsFailure(error.response.data));
        return error.response.data;
      });
  };
}
export function getAccounts() {
  return dispatch => {
    dispatch(accountsRequest())
    getBaseApiHelper(`/account`)
      .then(response => {
        dispatch(accountsSuccess(response.data))
      }).catch(error => {
        dispatch(accountsFailure(error))
      })
  }
}
export function getAccount(acctId) {
  return dispatch => {
    dispatch(accountRequest())
    getBaseApiHelper(`/account/${acctId}`)
      .then(response => {
        dispatch(accountSuccess(response.data))
      }).catch(error => {
        dispatch(accountFailure(error))
      })
  }
}

export function deleteUser(userId) {
  const url = `/users/${userId}`;
  return dispatch => {
    dispatch(deleteUserRequest());
    return deleteBaseApiHelper(url)
      .then(response => {
        dispatch(deleteUserSuccess(userId));
        return response;
      })
      .catch(rejected => {
        if(rejected.response.status === 404) {
          dispatch(deleteUserFailure(rejected.response.data));
          return rejected.response;
        } else {
          dispatch(deleteUserFailure(rejected.response));
          return Promise.reject(rejected.response)
        }
      });
  };
}

import React, { Component, Suspense, lazy } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route, Switch, withRouter, Redirect } from 'react-router-dom'
import { getFormSyncErrors, getFormSubmitErrors, formValueSelector } from 'redux-form'

import { allowUnreleased } from '../index'
import {
  resetPassword,
  getUser,
  loginAdmin
} from '../common/actions-reducers/auth-actions'
import { getConstants } from '../common/actions-reducers/constants-actions'
import { getProducts } from '../common/actions-reducers/products'

//Constant Components
import NavBar from '../components/NavBar/NavBar'
import Footer from '../components/Footer/Footer'
import FlashMessages from '../components/FlashMessages/FlashMessages'
import Loader from '../common/components/Loader'
import { StyledContainer } from './appStyles'
import UnreleasedVisibility from './UnreleasedVisibility'

//Auth
const Login2 = lazy(() => import('../components/auth2/Login'))
const Register2 = lazy(() => import('../components/auth2/Register'))
const MoreDetails = lazy(() => import('../components/auth2/MoreDetailsForm'))
const ResetPassword = lazy(() => import('../components/auth/ResetPassword'))

const NotFound = lazy(() => import('../components/NotFound/NotFound'))
const AccountPage = lazy(() => import('../components/MyAccount/MyAccount'))
const SampleSearchContainer = lazy(() => import('../components/SampleSearch/SampleSearchContainer'))
const UserSearchContainer = lazy(() => import('../components/UserSearch/UserSearchContainer'))
const UserDetailContainer = lazy(() => import('../components/UserView/UserDetailContainer'))
const ScanContainer = lazy(() => import ('../components/ScanQRCode/ScanContainer'))
const OrdersContainer = lazy(() => import ('../components/Orders/OrdersContainer'))

class App extends Component {
  /**
   * <App /> is the top level URI router for page components.
   * React-Router docs: https://reacttraining.com/react-router/web/guides/philosophy
   */
  constructor(props) {
    super(props)
    this.goBack = this.goBack.bind(this)
  }

  goBack() {
    this.props.history.goBack()
  }
  componentDidMount() {
    if (this.props.isAuthenticated) {
      this.props.dispatch(getConstants())
      this.props.dispatch(getUser())
      this.props.dispatch(getProducts())
    }
  }
  componentDidUpdate(prevProps) {
    if (!prevProps.isAuthenticated && this.props.isAuthenticated) {
      this.props.dispatch(getConstants())
      this.props.dispatch(getProducts())
    }
  }

  render() {
    const { dispatch, history, isAuthenticated, user,
      isFetchingAuth, betaVisible, unreleasedVisible} = this.props
    const fullScreenRoutes = ['register', 'login', 'complete_registration']
    const pathname = history.location.pathname
    const isFullScreen = fullScreenRoutes.includes(pathname.split('/')[1])
    return (
      <div className="app">
        {allowUnreleased && <UnreleasedVisibility dispatch={dispatch} visibility={unreleasedVisible}/>}
        {!isFullScreen &&
          <NavBar
            isAuthenticated={isAuthenticated}
            currentUser={user}
            dispatch={dispatch}
            unreleasedVisible={unreleasedVisible}
            betaVisible={betaVisible}
          />
        }
        <StyledContainer className={(isAuthenticated ? ' authenticated' : '')}>
          <FlashMessages />
          <Suspense fallback={<Loader height="20px" />}>
            <Switch>  {/* Public Routes */}
              <Route exact path='/' render={() =>
                isAuthenticated ? (
                  <Redirect to={{ pathname: '/my-account', state: { from: this.props.location } }} />
                ) : (
                  <Redirect to={{ pathname: '/login', state: { from: this.props.location } }} />
                )
              } />
              <Route
                exact path='/login'
                render={() => (
                  <Login2
                    onLoginClick={creds => dispatch(loginAdmin(creds))}
                    history={history}
                    dispatch={dispatch}
                    user={user}
                    isFetching={isFetchingAuth}
                  />
                )}
              />
              <Route
                exact path='/reset-password'
                render={() => (
                  <ResetPassword
                    onResetClick={creds => dispatch(resetPassword(creds))}
                    hasErrors={this.props.hasResetPassErrors}
                    history={history}
                    dispatch={dispatch}
                    isFetching={isFetchingAuth}
                  />
                )}
              />
              <Route
                exact path='/complete_registration/:token?'
                render={() => (
                  <MoreDetails
                    history={history}
                  />
                )}
              />
              <Route
                exact path='/register'
                render={() => (
                  <Register2
                    history={history}
                    dispatch={dispatch}
                  />
                )}
              />
              {/* Private Routes */}
              {/* TODO: fix this to make a PrivateRoute wrapper */}
              {isAuthenticated ? (
                <div className="auth-pages">
                  <Switch>
                    <Route path='/my-account' render={() =>
                      <AccountPage
                        user={user}
                        dispatch={dispatch}
                        history={history}
                      />
                    } />
                    <Route path='/search-samples/:urlIds?' render={({match}) => (
                      <SampleSearchContainer
                        urlIds={match.params.urlIds}
                      />
                    )} />
                    <Route exact path='/search-users' render={() => (
                      <UserSearchContainer
                        key='user-search'
                      />
                    )} />
                    <Route exact path='/view-user/:userId?' render={({ match }) => (
                      <UserDetailContainer
                        userId={match.params.userId}
                        user={user}
                        key={`user-view-${match.params.userId}`}
                      />
                    )} />
                    <Route exact path='/scan-qr-code' render={() => (
                      <ScanContainer
                        key='scan-qr-code'
                      />
                    )} />
                    <Route exact path='/orders' render={() => (
                      <OrdersContainer
                        key='orders'
                      />
                    )} />
                    <Route render={() => <NotFound
                      goBack={this.goBack}
                    />
                    } />
                  </Switch>
                </div>
              ) : (
                <Redirect to={{ pathname: '/login', state: { from: this.props.location } }} />
              )}
            </Switch>
          </Suspense>
        </StyledContainer>
        <Footer isAuthenticated={isAuthenticated} />
      </div>
    )
  }
}


// TODO: use selectors from reducer to unpack state to props
function mapStateToProps(state) {
  // Login form errors
  const loginSyncErrors = getFormSyncErrors('login')(state)
  const loginSubmitErrors = getFormSubmitErrors('login')(state)
  const hasLoginErrors = !!(
    (!!loginSyncErrors && (!!loginSyncErrors.email || !!loginSyncErrors.password)) ||
    (!!loginSubmitErrors && (!!loginSubmitErrors.email || !!loginSubmitErrors.password))
  )
  // Registration form
  const registerPassFieldValue = formValueSelector('register')(state, 'pw_1')
  const registerSyncErrors = getFormSyncErrors('register')(state)
  const registerSubmitErrors = getFormSubmitErrors('register')(state)
  const hasRegistrationErrors = !!(
    (!!registerSyncErrors && (
      !!registerSyncErrors['first-name'] ||
      !!registerSyncErrors['last-name'] ||
      !!registerSyncErrors.phone ||
      !!registerSyncErrors.email ||
      !!registerSyncErrors.pw_1 ||
      !!registerSyncErrors.pw_2
    )) ||
    (!!registerSubmitErrors && (
      !!registerSubmitErrors['first-name'] ||
      !!registerSubmitErrors['last-name'] ||
      !!registerSubmitErrors.phone ||
      !!registerSubmitErrors.email ||
      !!registerSubmitErrors.pw_1 ||
      !!registerSubmitErrors.pw_2
    ))
  )
  // UpdatePassword form
  const updatePassFieldValue = formValueSelector('updatePassword')(state, 'newPassword1')
  const hasUpdatePassSyncErrors = getFormSyncErrors('updatePassword')(state)
  const hasUpdatePassSubmitErrors = getFormSubmitErrors('updatePassword')(state)
  const hasUpdatePassErrors = !!(
    (!!hasUpdatePassSyncErrors && (!!hasUpdatePassSyncErrors.oldPassword || !!hasUpdatePassSyncErrors.newPassword1 || !!hasUpdatePassSyncErrors.newPassword2)) ||
    (!!hasUpdatePassSubmitErrors && (!!hasUpdatePassSubmitErrors.oldPassword || !!hasUpdatePassSubmitErrors.newPassword1 || !!hasUpdatePassSubmitErrors.newPassword2))
  )
  // ResetPassword form
  const hasResetPassSyncErrors = getFormSyncErrors('reset')(state)
  const hasResetPassSubmitErrors = getFormSubmitErrors('reset')(state)
  const hasResetPassErrors = !!(
    (!!hasResetPassSyncErrors && !!hasResetPassSyncErrors.email) ||
    (!!hasResetPassSubmitErrors && !!hasResetPassSubmitErrors.email)
  )

  const { auth, meta } = state
  const { isAuthenticated, user } = auth
  const { errorStatus, betaVisible } = meta
  const isFetchingAuth = auth.isFetching

  // props from state
  return {
    isAuthenticated,
    isFetchingAuth,
    user,
    // auth form errors
    hasLoginErrors,
    hasRegistrationErrors,
    hasUpdatePassErrors,
    hasResetPassErrors,
    // password field values, for matching
    registerPassFieldValue,
    updatePassFieldValue,
    meta,
    errorStatus,
    betaVisible
  }
}

App.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  dispatch: PropTypes.func,
  user: PropTypes.object,
  history: PropTypes.object,
  isFetchingAuth: PropTypes.bool,
  location: PropTypes.object,
  hasLoginErrors: PropTypes.bool,
  hasResetPassErrors: PropTypes.bool,
  hasRegistrationErrors: PropTypes.bool,
  registerPassFieldValue: PropTypes.string,
  betaVisible: PropTypes.bool,
  unreleasedVisible: PropTypes.bool
}

export default withRouter(connect(mapStateToProps)(App))

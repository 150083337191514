import axios from 'axios';

import { appStore } from '../../index';
import { logoutUser } from '../../common/actions-reducers/auth-actions';


const BASE_AUTH_URL = `${process.env.REACT_APP_BASE_API_URL}/auth`; // eslint-disable-line
const BASE_USERS_URL = `${process.env.REACT_APP_USERS_SERVICE_URL}` // eslint-disable-line
const BASE_ACCT_URL = `${process.env.REACT_APP_USERS_SERVICE_URL}/account/`; // eslint-disable-line

//rational for functions vs constants: need to make sure urls are updated on user change
const getAccountId = () => sessionStorage.getItem('accountId') || '';
const getToken = () => sessionStorage.getItem('authToken') || '';
const getHeaders = () => ({
  'Content-Type': `application/json`,
  'Authorization': `Bearer ${getToken()}`
});

export const buildQueryString = data => {
  if (typeof (data) === 'string') return data;
  let query = [];
  for (let key in data) {
    if (key in data) {
      // Encode each key and value, concatenate them into a string, and push them to the array
      if (typeof (data[key]) === 'string' || typeof (data[key]) === 'number' || typeof (data[key]) === 'boolean') {
        query.push(encodeURIComponent(key) + '=' + encodeURIComponent(data[key]));
      } else if (Array.isArray(data[key])) {
        // 'http://localhost/api/samples?tube_ids=TGI_123&tube_ids=TGI_456&include_values=False'
        data[key].forEach(el => {
          query.push(encodeURIComponent(key) + '=' + encodeURIComponent(el))
        })
      }
    }
  }
  return query.join('&');
};

// function factories
const getWrapper = (url) => (
  (endpoint, headers={}) => (
    axios.get(
      `${url}${endpoint}`,
      {headers: { ...getHeaders(), ...headers }}
    )
      .catch( (error) => {
        if(error.response && error.response.status === 401){
          appStore.dispatch(logoutUser());
        }
        throw error;
      })
  )
);

const postWrapper = (url)=> (
  (endpoint, data, headers={}) => (
    axios.post(
      `${url}${endpoint}`,
      data,
      {headers: {...getHeaders(),...headers}}
    )
    //If any API request returns unauthorized, log user out (clear tokens)
      .catch( (error) => {
        if(error.response && error.response.status === 401){
          appStore.dispatch(logoutUser());
        }
        throw error;
      })
  )
);

const patchWrapper = (url)=> (
  (endpoint, data, headers={}) => (
    axios.patch(
      `${url}${endpoint}`,
      data,
      {headers: {...getHeaders(),...headers}}
    )
      .catch( (error) => {
        if(error.response && error.response.status === 401){
          appStore.dispatch(logoutUser());
        }
        throw error;
      })
  )
);

const deleteWrapper = (url)=> (
  (endpoint, headers={}) => (
    axios.delete(
      `${url}${endpoint}`,
      {headers: {...getHeaders(),...headers}}
    )
      .catch( (error) => {
        if(error.response && error.response.status === 401){
          appStore.dispatch(logoutUser());
        }
        throw error;
      })
  )
);

// AUTH
export const getBaseAuthHelper = getWrapper(BASE_AUTH_URL);
export const postBaseAuthHelper = postWrapper(BASE_AUTH_URL);
export const patchBaseAuthHelper = patchWrapper(BASE_AUTH_URL);
// API/USERS
export const getBaseApiHelper = (endpoint, headers) => getWrapper(BASE_USERS_URL)(endpoint, headers);
export const postBaseApiHelper = (endpoint, data, headers) => postWrapper(BASE_USERS_URL)(endpoint, data, headers);
export const patchBaseApiHelper = patchWrapper(BASE_USERS_URL);
export const deleteBaseApiHelper = (endpoint, data, headers) => deleteWrapper(BASE_USERS_URL)(endpoint, data, headers);
// ACCOUNT
export const getAccountApiHelper = (endpoint, headers) => getWrapper(`${BASE_ACCT_URL}${getAccountId().slice(1,-1)}`)(endpoint, headers);
export const getAccountApiHelperCustom = (accountId, endpoint, headers) => getWrapper(`${BASE_ACCT_URL}${accountId}`)(endpoint, headers);
export const postAccountApiHelper = (endpoint, data, headers) => postWrapper(`${BASE_ACCT_URL}${getAccountId().slice(1,-1)}`)(endpoint, data, headers);
export const patchAccountApiHelper = (endpoint, data, headers) => patchWrapper(`${BASE_ACCT_URL}${getAccountId().slice(1,-1)}`)(endpoint, data, headers);
export const deleteAccountApiHelper = (endpoint, data, headers) => deleteWrapper(`${BASE_ACCT_URL}${getAccountId().slice(1,-1)}`)(endpoint, data, headers);

import { createSelector } from 'reselect'

import { getBaseApiHelper } from '../../common/utils/apiHelpers'

// ACTIONS
const GET_PRODUCTS_REQUEST = 'GET_PRODUCTS_REQUEST'
const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS'
const GET_PRODUCTS_FAILURE = 'GET_PRODUCTS_FAILURE'

const getProductsRequest = () => ({type: GET_PRODUCTS_REQUEST})
const getProductsSuccess = data => ({
  type: GET_PRODUCTS_SUCCESS,
  data
})
const getProductsFailure = error => ({
  type: GET_PRODUCTS_FAILURE,
  error
})

export const getProducts = () => {
  return dispatch => {
    dispatch(getProductsRequest())
    return getBaseApiHelper(`/products`).then(resp => {
      dispatch(getProductsSuccess(resp.data))
    }).catch(error => {
      dispatch(getProductsFailure(error.response))
    })
  }
}

// REDUCER
const initialState = {
  pending: false,
  error: null,
  byId: {}
}

export const productsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PRODUCTS_REQUEST: {
      return { ...state, pending: true, error: null }
    }
    case GET_PRODUCTS_SUCCESS: {
      return { ...state, pending: false, error: null, byId: action.data.reduce((byId, product) => {
        return Object.assign({}, byId, { [product.id]: product })
      }, { ...state.byId })}
    }
    case GET_PRODUCTS_FAILURE: {
      return { ...state, pending: false, error: action.error }
    }
    default: {
      return { ...state }
    }
  }
}

//////////////////
// SELECTORS
//////////////////
const getProductsById = state => state.entities.products.byId

export const getProductOptions = createSelector(
  getProductsById,
  productsById => {
    const sortedProducts = Object.values(productsById).sort((a, b) => a.name > b.name ? 1 : -1)
    return sortedProducts.map(product => ({value: product.id, label: `${product.name} v${product.version}`}))
  }
)

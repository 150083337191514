import {
  postBaseApiHelper,
  getBaseApiHelper,
  buildQueryString
} from '../../common/utils/apiHelpers'


// ACTIONS
/*
* Get an order by ID
*/
const GET_ORDER_REQUEST = 'GET_ORDER_REQUEST'
const GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS'
const GET_ORDER_FAILURE = 'GET_ORDER_FAILURE'

const getOrderRequest = () => ({type: GET_ORDER_REQUEST})
const getOrderSuccess = data => ({
  type: GET_ORDER_SUCCESS,
  data
})
const getOrderFailure = error => ({
  type: GET_ORDER_FAILURE,
  error
})

export const getOrder = orderId => {
  return dispatch => {
    dispatch(getOrderRequest())
    return getBaseApiHelper(`/orders/${orderId}`).then(resp => {
      dispatch(getOrderSuccess(resp.data))
    }).catch(error => {
      dispatch(getOrderFailure(error.response))
    })
  }
}
/*
* Get all orders (data is for query string)
*/
export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS'

const getOrdersSuccess = data => ({
  type: GET_ORDERS_SUCCESS,
  data
})

export const getOrders = data => {
  return dispatch => {
    dispatch(getOrderRequest())
    return getBaseApiHelper(`/orders?` + buildQueryString(data)).then(resp => {
      dispatch(getOrdersSuccess(resp.data))
      return resp.data
    }).catch(error => {
      dispatch(getOrderFailure(error.response))
      return error.response
    })
  }
}

/*
* Post an order with Modus XML
*/
const POST_MODUS_SUBMIT_XML_REQUEST = 'POST_MODUS_SUBMIT_XML_REQUEST'
const POST_MODUS_SUBMIT_XML_SUCCESS = 'POST_MODUS_SUBMIT_XML_SUCCESS'
const POST_MODUS_SUBMIT_XML_FAILURE = 'POST_MODUS_SUBMIT_XML_FAILURE'

const postModuxXmlRequest = () => ({type: POST_MODUS_SUBMIT_XML_REQUEST})
const postModuxXmlSuccess = data => ({
  type: POST_MODUS_SUBMIT_XML_SUCCESS,
  data
})
const postModuxXmlFailure = error => ({
  type: POST_MODUS_SUBMIT_XML_FAILURE,
  error
})

export const postModusXml = xml => {
  return dispatch => {
    dispatch(postModuxXmlRequest)
    return postBaseApiHelper(
      `/orders/modus_submit`,
      xml,
      {'Content-Type': 'application/xml'}).then(resp => {
      dispatch(postModuxXmlSuccess(resp.data))
    }).catch(error => {
      dispatch(postModuxXmlFailure(error.response))
    })
  }
}

/*
* Create order
*/
const CREATE_ORDER_REQUEST = 'CREATE_ORDER_REQUEST'
const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS'
const CREATE_ORDER_FAILURE = 'CREATE_ORDER_FAILURE'

const createOrderRequest = () => ({ type: CREATE_ORDER_REQUEST })
const createOrderSuccess = order => ({ type: CREATE_ORDER_SUCCESS, order })
const createOrderFailure = error => ({ type: CREATE_ORDER_FAILURE, error })

export function postOrder(data) {
  return dispatch => {
    dispatch(createOrderRequest())
    return postBaseApiHelper('/orders', data)
      .then(resp => {
        dispatch(createOrderSuccess(resp.data))
        return resp.data
      })
      .catch(rejected => {
        dispatch(createOrderFailure(rejected))
        return Promise.reject(rejected)
      })
  }
}

/*
* Post samples to order
*/
const ORDER_SAMPLE_POST_REQUEST = 'ORDER_SAMPLE_POST_REQUEST'
const ORDER_SAMPLE_POST_SUCCESS = 'ORDER_SAMPLE_POST_SUCCESS'
const ORDER_SAMPLE_POST_FAILURE = 'ORDER_SAMPLE_POST_FAILURE'

const postOrderSampleRequest = () => ({ type: ORDER_SAMPLE_POST_REQUEST })
const postOrderSampleSuccess = (orderId, payload) => ({
  type: ORDER_SAMPLE_POST_SUCCESS,
  orderId,
  samples: payload.samples,
  sampleErrors: payload.sampleErrors,
  deletions: payload.deletions
})
const postOrderSampleFailure = error => ({
  type: ORDER_SAMPLE_POST_FAILURE,
  serverError: error,
})

export function postOrderSamples(orderId, data) {
  return dispatch => {
    dispatch(postOrderSampleRequest())
    return postBaseApiHelper(`/orders/${orderId}/samples`, data)
      .then(resp => {
        let postOrderSampleResponse = {
          samples: resp.data.samples,
          sampleErrors: resp.data.errors,
          deletions: resp.data.deletions
        }
        dispatch(postOrderSampleSuccess(orderId, postOrderSampleResponse))
        return Promise.resolve(postOrderSampleResponse)
      })
      .catch(rejected => {
        dispatch(postOrderSampleFailure(rejected))
        return Promise.reject(rejected)
      })
  }
}

/*
* Create orders and post samples
*/
const CREATE_ORDERS_REQUEST = 'CREATE_ORDERS_REQUEST'
const CREATE_ORDERS_SUCCESS = 'CREATE_ORDERS_SUCCESS'
const CREATE_ORDERS_FAILURE = 'CREATE_ORDERS_FAILURE'

const createOrdersRequest = () => ({ type: CREATE_ORDERS_REQUEST })
const createOrdersSuccess = () => ({ type: CREATE_ORDERS_SUCCESS })
const createOrdersFailure = error => ({ type: CREATE_ORDERS_SUCCESS, error })

export const createOrders = (orders, selectedAccount) => {
  return dispatch => {
    dispatch(createOrdersRequest())
    const orderPromises = (orders, selectedAccount) => orders.reduce((acc, order) => acc.concat(
      dispatch(postOrder({
        ordering_account_id: selectedAccount,
        field_id: order.sampleMetaData[0].field_id
      }))), [])

    const orderSamplesPromises = (orders, orderIds) => orders.reduce((acc, order, orderIndex) => acc.concat(
      dispatch(postOrderSamples(
        orderIds[orderIndex],
        { samples: order.samples }
      ))), [])

    return Promise.all(orderPromises(orders, selectedAccount))
      // create all orders
      .then(ordersResponse => Promise.all(orderSamplesPromises(orders, ordersResponse.map(({ id }) => id))))
      // add samples to each order
      .then(orderSamplesResponse => {
        dispatch(createOrdersSuccess())
        return Promise.resolve(orderSamplesResponse)
      })
      .catch(error => {
        dispatch(createOrdersFailure(error))
        return Promise.reject()
      })
  }
}

// REDUCER
const initialState = {
  pending: false,
  order: null,
  error: null,
  byId: {}
}

export const ordersReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_ORDERS_REQUEST:
    case GET_ORDER_REQUEST: {
      return { ...state, pending: true, error: null }
    }
    case GET_ORDER_SUCCESS: {
      return { ...state, pending: false, byId: {
        ...state.byId, [action.data.id]: action.data}
      }
    }
    case CREATE_ORDERS_SUCCESS: {
      return { ...state, pending: false, error: null }
    }
    case CREATE_ORDERS_FAILURE:
    case GET_ORDER_FAILURE: {
      return { ...state, pending: false, error: action.error }
    }
    case GET_ORDERS_SUCCESS: {
      return { ...state, pending: false, error: null, byId: action.data.reduce((byId, order) => {
        return Object.assign({}, byId, { [order.id]: order })
      }, { ...state.byId })}
    }
    case POST_MODUS_SUBMIT_XML_REQUEST: {
      return { ...state, pending: true, order: null, error: null }
    }
    case POST_MODUS_SUBMIT_XML_SUCCESS: {
      return { ...state, pending: false, order: action.data, error: null }
    }
    case POST_MODUS_SUBMIT_XML_FAILURE: {
      return { ...state, pending: false, error: action.error, order: null}
    }
    default: {
      return { ...state }
    }
  }
}
